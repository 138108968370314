import { Inject, Injectable } from '@angular/core';
import { WA_WINDOW } from '@ng-web-apis/common';

type HeapWindow = typeof WA_WINDOW & {
  // this is optional because it can be blocked
  heap?: {
    addUserProperties: (x: unknown) => void;
    resetIdentity: () => void;
    identify: (id: unknown) => void;
    addEventProperties: (x: unknown) => void;
  };
};

@Injectable({
  providedIn: 'root',
})
export class HeapService {
  constructor(@Inject(WA_WINDOW) private readonly window: HeapWindow) {}

  public addUserProperties(properties: Record<string, unknown>) {
    // https://developers.heap.io/docs/using-identify#when-and-where-to-call-identify-and-adduserproperties
    this.window.heap?.addUserProperties(properties);
  }

  public resetIdentity(): void {
    this.window.heap?.resetIdentity();
  }

  public identify(id: unknown): void {
    this.window.heap?.identify(id);
  }

  public addEventProperties(properties: Record<string, unknown>) {
    this.window.heap?.addEventProperties(properties);
  }
}
