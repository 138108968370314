import { EnvironmentProviders, makeEnvironmentProviders, NgZone, inject, provideAppInitializer } from '@angular/core';
import { Environment, PlatformService } from '@rma/generic/util/environment';
import { HeapScriptService } from './heap-script.service';

function initialiseHeap(
  environment: Environment,
  platformService: PlatformService,
  heapScriptService: HeapScriptService,
  zone: NgZone,
): () => void {
  return () => {
    if (platformService.isPlatformBrowser && environment.tracking.heapAppId) {
      zone.runOutsideAngular(() => heapScriptService.initialiseHeap());
    }
  };
}

export function provideRmaHeap(): EnvironmentProviders {
  return makeEnvironmentProviders([
    provideAppInitializer(() => {
      const initializerFn = initialiseHeap(inject(Environment), inject(PlatformService), inject(HeapScriptService), inject(NgZone));
      return initializerFn();
    }),
  ]);
}
